import React from "react";

class Futer extends React.Component {
    render() {
        return (
            <div class="container">
                <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <div class="col-md-4 d-flex align-items-center">
                    <span class="mb-3 mb-md-0 text-body-secondary">© 2018-2025 128 BIT, Inc</span>
                    </div>

                    <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
                    </ul>
                </footer>
            </div>
        )
    }
}

export default Futer