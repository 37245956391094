import React from "react"
import HomePage from "./Home_page";


import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

class App extends React.Component {
  hello = "hello"
  render(){
    return (
      <Router>
            <Routes>
                <Route exact path="/" element={<HomePage />} />
            </Routes>
        </Router>
    )
  }
}

export default App 